import React, { ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setOnline } from "../../store/features/appSlice";

interface iProps {
  children: ReactElement;
}

function PropertyVH() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
const AppWrapper: React.FC<iProps> = ({ children }: iProps) => {
  const dispatch = useDispatch();
  const updateOnlineStatus = useCallback(() => {
    dispatch(setOnline(navigator.onLine))

    if (navigator.onLine) {
      document.body.classList.remove("offline");
      document.body.classList.add("online");
    } else {
      document.body.classList.remove("online");
      document.body.classList.add("offline");
    }


  }, [])

  useEffect(() => {
    window.addEventListener('resize', PropertyVH);
    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    updateOnlineStatus();
    PropertyVH();
  }, [])

  const appOnline = useSelector((state: RootState) => state.app.online);
  return (<>{children}</>);
};

export default AppWrapper;
