import arrow from './arrow.svg';
import backWhite from './back-white.svg';
import cittaSalute from './citta_salute.png';
import icoNavConditions from './ico-nav-conditions.png';
import icoNavDrugs from './ico-nav-drugs.png';
import icoNavHome from './ico-nav-home.png';
import icoNavLegend from './ico-nav-legend.png';
import icoPill from './pill.svg';
import logo from './logo.png';
import icoSearch from './ico-search.png';
import icoSearchClose from './ico-search-close.png';

export default {
  arrow: { src: arrow, alt: '' },
  backWhite: { src: backWhite, alt: 'back' },
  cittaSalute: { src: cittaSalute, alt: 'Città della Salute' },
  icoNavConditions: { src: icoNavConditions, alt: '' },
  icoNavDrugs: { src: icoNavDrugs, alt: '' },
  icoNavHome: { src: icoNavHome, alt: '' },
  icoNavLegend: { src: icoNavLegend, alt: '' },
  icoPill: { src: icoPill, alt: 'Antibiotics Guidelines' },
  icoSearch: { src: icoSearch, alt: 'Cerca' },
  icoSearchClose: { src: icoSearchClose, alt: '' },
  logo: { src: logo, alt: 'Antibiotics Guidelines' },
}