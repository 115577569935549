import React from "react";

import Images from '../../assets/images';

import './styles.sass';

interface Props {
  value: string;
  placeholder: string;
  onSearch: (text: string) => void;
}

const Search: React.FC<Props> = ({ value, placeholder, onSearch }: Props) => {
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const text = e.target.value;
    onSearch(text);
  }

  function onResetClicked() {
    onSearch('');
  }

  return (
    <div className="search">
      <img src={Images.icoSearch.src} alt={Images.icoSearch.alt} />
      <input value={value} onChange={onChange} placeholder={placeholder} />
      {value && (
        <button type="button" onClick={onResetClicked}>
          <img src={Images.icoSearchClose.src} alt="" />
        </button>
      )}
    </div>
  );
};

export default Search;
