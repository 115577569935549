import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

import { enumRoutes } from '../../libs/enums';
import history from '../../store/history';

import Images from '../../assets/images';

import './styles.sass';


interface iPropsItem {
  text: string;
  icon: string;
  link: string;
}

const NavBurgerItem: React.FC<iPropsItem> = ({text, icon, link}: iPropsItem) => {
  return(
    <li>
      <Link to={link}>
        <span><img src={icon} alt="" /></span>
        {text}
      </Link>
    </li>
  )
}


interface iProps {
  visible: boolean;
}
const NavBurger: React.FC<iProps> = ({ visible }: iProps) => {
  
  return (
    <>
      {visible && (
        <div className="nav-burger">
          <nav>
            <ul>
              <NavBurgerItem
                text="home"
                icon={Images.icoNavHome.src}
                link={enumRoutes.home}
              />
              <NavBurgerItem
                text={I18n.t("activerecord.models.clinical_condition", { count: 2 })}
                icon={Images.icoNavConditions.src}
                link={enumRoutes.clinicalConditions}
              />
              <NavBurgerItem
                text={I18n.t("frontend.drug", { count: 2 })}
                icon={Images.icoNavDrugs.src}
                link={enumRoutes.drugs}
              />
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default NavBurger;
