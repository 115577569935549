import React from "react";
import { Link } from 'react-router-dom';
import Images from '../../assets/images';
import './styles.sass';

interface iProps {
  path: string;
  label: string;
}

const ItemLink: React.FC<iProps> = ({ path, label }: iProps) => {
  return (
    <Link to={path} className="item-link">
      <span>{label}</span>
      {/*<img src={Images.arrow.src} alt="" />*/}
    </Link>
  );
};

export default ItemLink;
