import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import I18n from 'i18n-js';
// TODO impostare locale globale
I18n.locale = 'it';

import { RootState } from '../../store';
import { Header, Details } from '../../components';
import { iDrugApi, iTreatment } from '../../libs/models';
import { enumTitles, enumRoutes } from '../../libs/enums';
import { parseString } from '../../libs/helpers/commons';

import {
  clinicalConditionsAsyncGetTreatmentAction,
  clinicalConditionsResetTreatmentAction, 
} from '../../store/features/clinicalConditionsSlice';

import './styles.sass';

interface iProps {
  match: any;
}

const TreatmentView: React.FC<iProps> = ({ match }: iProps) => {
  const { params } = match;
  const { id } = params;
  const dispatch = useDispatch();
  
  useEffect(() => {
    return () => {
      dispatch(clinicalConditionsResetTreatmentAction());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(clinicalConditionsAsyncGetTreatmentAction(id));
  }, [dispatch, id]);

  const details: iTreatment = useSelector((state: RootState) => state.clinicalConditions.treatment);
  const linkBack = `${enumRoutes.clinicalConditions}?active=${id}`;

  return (
    <>
      <Header title={enumTitles.treatment} linkBack={linkBack}/>
      {details && (
        <Details>
          
          <h1>{details.name}</h1>
          <div className="px-20 py-10">{parseString(details.description)}</div>
          {details.variations && (
            <>
              <h2>{I18n.t("activerecord.attributes.treatment.variations")}</h2>
              <div className="px-20 py-10">{parseString(details.variations)}</div>
            </>
          )}
          {details.notes && (
            <>
              <h2>{I18n.t("activerecord.attributes.treatment.notes")}</h2>
              <div className="px-20 py-10">{parseString(details.notes)}</div>
            </>
          )}
          <>
            <h2>{I18n.t("info")}</h2>
            <div className="px-20 py-10">{I18n.t("activerecord.attributes.treatment.updated_at")}: {I18n.l("date.formats.default", details.updated_at)}</div>
          </>
         <ReactTooltip />  
        </Details>
       
      )}
    </>
  )
}

export default TreatmentView;