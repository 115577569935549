import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { History } from 'history'
import { connectRouter } from 'connected-react-router'

import app from './features/appSlice';
import user from './features/userSlice';
import drugs from './features/drugsSlice';
import clinicalConditions from './features/clinicalConditionsSlice';


const rootReducer = (history: History): any => combineReducers({
  app,
  drugs,
  clinicalConditions,
  user,
  router: connectRouter(history),
});



export default rootReducer;