import React from 'react'
import moment from "moment";
import { Link } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';

import { iNavItem, NavItemType } from '../models';

export const getAddress = () =>
  `${global.location.protocol}//${global.location.host}/`;

// export const capitalizeFirstLetter = (string) =>
//   string.charAt(0).toUpperCase() + string.slice(1);

export const wait = (durationMs: number) =>
  new Promise((fulfill) => setTimeout(fulfill, durationMs));

export const refreshPage = () => {
  window.location.reload();
};

export const currencyDecimal = (value: string) => {
  return Number.parseFloat(value).toFixed(2).toString().concat(" €");
};

export const currencyNoDecimal = (value: string) => {
  return Number.parseFloat(value).toFixed(0).toString().concat("€");
};

export const isMobileDevice = () => window.screen.width < 767;

export const isTouchScreen = () =>
  !!("ontouchstart" in window || navigator.maxTouchPoints);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToId = (id: string) => {
  const element = document.getElementById(id);
  // if (element) element.scrollIntoView();
  if (element) {
    const top = element.offsetTop;
    window.scrollTo({ top });
  }
};

export const getAge = (birthdate: Date) => moment().diff(moment(birthdate), "years");


export const isEmptyOrSpaces = (str: string) => {
  return str === null || str.match(/^ *$/) !== null || str.length === 0;
};


export function asyncThunkErrorHelper(rejectWithValue: any, err: any): any {
  const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
  // toast.error(errorMessage);
  return rejectWithValue(err);
}


export function parseString(text: string | null) {
  if (typeof text === "string") {
    const t = text;
    const options: any = {
      replace: (
        { name, attribs, children }:
        {name: string, attribs: any, children: any}
      ) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
      }
    };
    return parse(t, options);
  }
  return text;
}

export function objFromUrlParams(): any {
  const urlSearch = window.location.search;
  let obj: any = {};
  const locationSearch = urlSearch.substring(1);
  if (locationSearch) {
    obj = JSON.parse(
      `{"${decodeURI(locationSearch)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`,
    );
  }
  return obj;
}


export function filterList(params: { search: string, list: iNavItem[], linkType: NavItemType }) {
  const { search, list, linkType} = params;
  if (!search) {
    return list;
  }
  let arrayTreatments: iNavItem[] = []

  function filter(list: iNavItem[]) {
    list.forEach((item: iNavItem) => {
      if (item.type === linkType && item.name.toLowerCase().includes(search.toLowerCase())) {
        arrayTreatments.push(item);
      }
      if (item.children?.length) {
        filter(item.children);
      }
      if (item.items?.length) {
        filter(item.items);
      }
    });
  }
  filter(list);
  return arrayTreatments;
}

export function findDetailsOffline(params: { id: string, list: iNavItem[] }) {
  const { id, list} = params;
  let arrayTreatments: iNavItem[] = []
  function filter(list: iNavItem[]) {
    list.forEach((item: iNavItem) => {
      if (typeof item.id === 'string' && item.id.includes(id)) {
        arrayTreatments.push(item);
      }
      if (item.children?.length) {
        filter(item.children);
      }
      if (item.items?.length) {
        filter(item.items);
      }
    });
  }
  filter(list);

  return arrayTreatments[0];
}