export const PushDataLayer = data => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const PageView = (page = window.location.href) =>
  PushDataLayer({
    event: "PageView",
    VPpageUrl: page
  });

export const PushCustomLayer = order => {
  const { param } = order;
  PushDataLayer({
    event: "CustomEvent",
    data: {
      param
    }
  });
};
