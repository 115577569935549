import React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';

import store, { persistor } from './store'
import history from "./store/history";
import { Router, AppWrapper, NoServiceWorker } from "./components";
import routes from "./config/routes";
import './assets/stylesheets/styles.sass';

import * as googleAnalytics from 'workbox-google-analytics';

ReactGA.initialize('UA-51367346-9', {
  debug: false
});


declare global {
  interface Window {
      LOCALE: string;
  }
}

const App: React.FunctionComponent = () => {
  if (
    self.location.hostname.match('guidelines.epiclin.it') !== null
    && self.location.hostname.match('guidelines.epiclin.it').length > 0
  ) {
    // Initialize google analytics page view tracking
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }
 
  return (
    <Provider store={store} context={ReactReduxContext}>
      <PersistGate loading={null} persistor={persistor}>
        <NoServiceWorker />
        <AppWrapper>
          <ConnectedRouter history={history}>
            <Router routes={routes} />
          </ConnectedRouter>
        </AppWrapper>
      </PersistGate>
    </Provider>
    
  );
};

export default App;
