/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { getLoginApi } from '../../api/user.api';
import callApi from "../../libs/callApi";

import { setLoading, setloadingTest } from './appSlice';

// Slice

interface iLoginPayload {
  username: string;
  password: string;
}

interface iUserState {
  email: string | null,
  token: string,
}

const initialState: iUserState = {
  email: 'pippo',
  token: '',
}

export const loginAsyncAction = createAsyncThunk(
  'user/loginAsyncAction',
  async (payload: iLoginPayload, { rejectWithValue, getState, dispatch }) => {
    setloadingTest();
    dispatch(setLoading(true));
    try {
      const response = await getLoginApi();
      dispatch(setLoading(false));
      return response;
    } catch (err) {
      console.log('err', err );
      dispatch(setLoading(false));
      return rejectWithValue(err);
    }
  }
);



const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSet: (state, action) => {
      state.email = action.payload;
    },
    loginSuccess: (state, action) => {
      state.email = action.payload;
    },
    logoutSuccess: (state) =>  {  
      state.email = null;
    },
  },
  extraReducers: {
    [loginAsyncAction.pending.type]: (state) => {
    },
    [loginAsyncAction.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    [loginAsyncAction.rejected.type]: (state) => {
      state.email = '';
    },
  }
});

export const { loginSuccess, logoutSuccess, loginSet } = slice.actions;
export default slice.reducer;



// export const login = ({ username, password }: iLoginPayload) => async (dispatch: any) => {
//   console.log('username', username)
//   try {
//     // const res = await api.post('/api/auth/login/', { username, password })
//     const res = await loginApi();
//     console.log('res', res);
//     return dispatch(loginSuccess(res.title));
//   } catch (e) {
//     return console.error(e.message);
//   }
// }

export const logout = () => async (dispatch: any) => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}