/**
 * Gets Cookie from name
 *
 * @author davide.mantovani
 *
 * @param {String} name cookie name
 *
 * @returns {String} This method returns the cookie if found, or undefined if null
 */
export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return match[2];
  return undefined;
};

export const setCookie = (name: string, value: string, days = 365) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name  }=${value || ""}${expires}; path=/;`;
};
