/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface iError {
  name: string;
  status: number;
  message?: string;
  id: string | number;
  date: Date | string;
  code?: string | number;
  stack?: any;
}

interface iAppState {
  loading: boolean,
  error: iError | null,
  online: boolean
}

const initialState: iAppState = {
  loading: false,
  error: null,
  online: true
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Pippooooo', action.payload)
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<iError | null>) => {
      state.error = action.payload;
    },
    setOnline: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload;
    },
    appResetError: (state) => {
      state.error = null;
    }
  },
});

export const { setLoading, setError, appResetError, setOnline } = slice.actions;

export const setloadingTest = () => async (dispatch: any) => {
  console.log('setloadingTest');
  return  dispatch(setLoading(true));

}

export default slice.reducer