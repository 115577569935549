import React from "react";
import './styles.sass';

interface iProps {
  children: any;
}

const Details: React.FC<iProps> = ({ children }: iProps) => {
  return (
    <div className="details">
      <main>
        <article>
          {children}
        </article>
      </main>
    </div>
  );
};

export default Details;
