import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import { enumRoutes } from '../../libs/enums';
import Images from '../../assets/images';
import './styles.sass';

import I18n from 'i18n-js';
I18n.locale = 'IT';

const HomeView: React.FC = () => {
  return (
    <div className="home">
      <header className="home__header">
        <img src={Images.logo.src} alt={Images.logo.alt} />
      </header>
      <main>
        <ul className="home__list">
          <li><Link to={enumRoutes.clinicalConditions} className="App-link">{I18n.t("activerecord.models.clinical_condition", { count: 2 })}</Link></li>
          <li><Link to={enumRoutes.drugs} className="App-link">{I18n.t("frontend.drug", { count: 2 })}</Link></li>
        </ul>
        <div className="citta-salute">
          <img src={Images.cittaSalute.src} alt={Images.cittaSalute.alt} />
        </div>
      </main>
      <footer>
        <Link to={enumRoutes.home}>CREDITS</Link>
      </footer>
    </div>
  )
}

export default HomeView;