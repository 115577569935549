import { lazy } from "react";

// internal libs
import { type } from "../components/helpers/router";
import { AppLayout, HomeLayout } from "../components/layouts";
import Localize from "../libs/localization";

import {
  DrugsView,
  DrugsDetailsView,
  ClinicalConditionsView,
  ClinicalConditionsDetailsView,
  HomeView,
  TreatmentView,
} from '../views/index';
import { enumRoutes } from '../libs/enums';

// const Login = lazy(() => import("../containers/Login/Login"));
// const Home = lazy(() => import("../containers/Home/Home"));

export default [
  {
    id: "home",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.home,
    layout: HomeLayout,
    component: HomeView,
    type: type.PUBLIC,
  },
  {
    id: "drugs",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.drugs,
    layout: AppLayout,
    component: DrugsView,
    type: type.PUBLIC,
  },
  {
    id: "drugsDetails",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.drugsDetails,
    layout: AppLayout,
    component: DrugsDetailsView,
    type: type.PUBLIC,
  },
  {
    id: "clinicalConditions",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.clinicalConditions,
    layout: AppLayout,
    component: ClinicalConditionsView,
    type: type.PUBLIC,
  },
  {
    id: "clinicalConditionsDetails",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.clinicalConditionsDetails,
    layout: AppLayout,
    component: ClinicalConditionsDetailsView,
    type: type.PUBLIC,
  },
  {
    id: "treatment",
    title: Localize("PAGES.REQUEST.TITLE"),
    path: enumRoutes.treatment,
    layout: AppLayout,
    component: TreatmentView,
    type: type.PUBLIC,
  },
];
