import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import I18n from 'i18n-js';

import { RootState } from '../../store';
import { enumRoutes } from '../../libs/enums';
import { Header, NavSlide, Search } from '../../components';
import { objFromUrlParams, filterList } from '../../libs/helpers/commons';
import { iNavItem } from '../../libs/models'

import { clinicalConditionsAsyncGetListAction } from '../../store/features/clinicalConditionsSlice';

I18n.locale = 'it';

let debounce: any;
const ClinicalConditionsView: React.FC = () => {
  const dispatch = useDispatch();
  const urlParamsObj = objFromUrlParams();
  const clinicalConditions = useSelector((state: RootState) => state.clinicalConditions);

  const [search, searchSet] = useState<string>('')
  const [listFiltered, listFilteredSet] = useState<iNavItem[]>([])

  useEffect(() => {
    dispatch(clinicalConditionsAsyncGetListAction(null));
  }, [dispatch]);

  // TODO riportare logica in hook comune
  useEffect(() => {
    listFilteredSet(clinicalConditions.list);
  }, [clinicalConditions.list]);

  useEffect(() => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      onFilterList()
    }, 300);
  }, [search]);

  function onFilterList() {
    if (clinicalConditions.list.length) {
      const arrayTreatments = filterList({search, list: clinicalConditions.list, linkType: "treatment"})
      listFilteredSet(arrayTreatments);
    }
  }

  function onSearch(text: string) {
    searchSet(text);
  }

  return (
    <>
      <Header
        title={I18n.t("activerecord.models.clinical_condition", { count: 2 })}
        linkBack={enumRoutes.home}
      />
      <main>
        <Search value={search} onSearch={onSearch} placeholder={I18n.t("search")} />
        <NavSlide
          list={listFiltered}
          route={enumRoutes.treatment}
          activeItem={urlParamsObj.active ? urlParamsObj.active : undefined}
        />
      </main>
    </>
  )
}

export default ClinicalConditionsView;