/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import {
  clinicalConditionsGetListApi,
  clinicalConditionsGetDetailsApi,
  clinicalConditionsGetTreatmentApi,
} from '../../api/clinicalConditionsApi';
import {
  iTreatment,
  iClinicalConditionsDetails,
  iNavItem
} from '../../libs/models';


// import fake from '../api/tree.json';

interface iClinicalConditionsState {
  list: iNavItem[];
  details: iClinicalConditionsDetails | null;
  treatment: iTreatment | null;
}


const initialState: iClinicalConditionsState = {
  list: [],
  details: null,
  treatment: null,
};

const slice = createSlice({
  name: 'clinicalConditions',
  initialState,
  reducers: {
    clinicalConditionsSetListAction: (state, action: PayloadAction<iNavItem[]>) => {
      state.list = action.payload;
    },
    clinicalConditionsGetDetailsAction: (state, action: PayloadAction<iClinicalConditionsDetails>) => {
      state.details = action.payload;
    },
    clinicalConditionsGetTreatmentAction: (state, action: PayloadAction<iTreatment>) => {
      state.treatment = action.payload;
    },
    clinicalConditionsResetDetailsAction: (state) => {
      state.details = null;
    },
    clinicalConditionsResetTreatmentAction: (state) => {
      state.treatment = null;
    },
  },
});

export const {
  clinicalConditionsSetListAction,
  clinicalConditionsGetDetailsAction,
  clinicalConditionsGetTreatmentAction,
  clinicalConditionsResetDetailsAction,
  clinicalConditionsResetTreatmentAction,
} = slice.actions;

export default slice.reducer;

export const clinicalConditionsAsyncGetListAction = createAsyncThunk(
  'clinicalConditions/clinicalConditionsAsyncGetListAction',
  async (payload: null, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: iNavItem[] = await clinicalConditionsGetListApi();
      dispatch(clinicalConditionsSetListAction(response));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const clinicalConditionsAsyncGetDetailsAction = createAsyncThunk(
  'clinicalConditions/clinicalConditionsAsyncGetDetailsAction',
  async (payload: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: iClinicalConditionsDetails = await clinicalConditionsGetDetailsApi(payload);
      dispatch(clinicalConditionsGetDetailsAction(response));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const clinicalConditionsAsyncGetTreatmentAction = createAsyncThunk(
  'clinicalConditions/clinicalConditionsAsyncGetTreatmentAction',
  async (payload: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: iTreatment = await clinicalConditionsGetTreatmentApi(payload);
      dispatch(clinicalConditionsGetTreatmentAction(response));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);