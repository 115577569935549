import I18n from 'i18n-js';

I18n.locale = window.LOCALE;

export enum enumTitles {
  treatment = 'Trattamento',
}
const drugsRoute = '/' + I18n.t("routes.drugs");
const drugsDetailsRoute = '/' + I18n.t("routes.drugs") + '/:id';
const clinicalConditionRoute = '/' + I18n.t("routes.clinical_conditions");
const clinicalConditionDetailRoute = '/' + I18n.t("routes.clinical_conditions") + '/:id';
const treatmentRoute = '/' + I18n.t("routes.treatments") + '/:id';
// export enum enumRoutes {
//   home = '/',
//   drugs = drugsRoute,
//   drugsDetails = drugsDetailsRoute,
//   clinicalConditions = clinicalConditionRoute,
//   clinicalConditionsDetails = clinicalConditionDetailRoute,
//   treatment= treatmentRoute
// }
export enum enumRoutes {
  home = '/',
  drugs = '/drugs',
  drugsDetails = '/drugs/:id',
  clinicalConditions = '/clinical_conditions',
  clinicalConditionsDetails = '/clinical_conditions/:id',
  treatment= '/treatment/:id',
}

