import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import I18n from 'i18n-js';
I18n.locale = 'it';

import { RootState } from '../../store';
import {
  clinicalConditionsAsyncGetDetailsAction,
  clinicalConditionsResetDetailsAction,
} from '../../store/features/clinicalConditionsSlice';

import history from '../../store/history';
import { enumRoutes } from '../../libs/enums';
import { iClinicalConditionsDetails, iTreatment } from '../../libs/models';
import { Header, Details, NavItemLink } from '../../components';

interface iProps {
  match: any;
}

const ClinicalConditionsDetailsView: React.FC<iProps> = ({ match }: iProps) => {
  const { params } = match;
  const { id } = params;
  const dispatch = useDispatch();
  const details: iClinicalConditionsDetails = useSelector((state: RootState) => state.clinicalConditions.details);

  useEffect(() => {
    dispatch(clinicalConditionsAsyncGetDetailsAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clinicalConditionsResetDetailsAction());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (details?.treatments.length === 1) {
  //     const id = details.treatments[0].id;
  //     const path = `${enumRoutes.treatment.replace(':id', '')}${id}`;
  //     history.push(path);
  //   }
  // }, [details])


  

  console.log('details.treatments', details?.treatments);
  return (
    <div>
      <Header
        title={I18n.t("activerecord.models.treatment", { count: 2 })}
        linkBack={enumRoutes.clinicalConditions}
      />
      {details && (
        <Details>
          <h1>{details.name}</h1>
          <ul className="ul-reset">
          {details.treatments.map((t: iTreatment) => {
            const path = `${enumRoutes.treatment.replace(':id', '')}${t.id}`;
            return (
              <li key={t.id}>
                <NavItemLink path={path} label={t.title} />
              </li>
            )
          })}
          </ul>
        </Details>
      )}
    </div>
  )
}

export default ClinicalConditionsDetailsView;