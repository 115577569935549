import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { iNavItem } from '../../libs/models'

import I18n from 'i18n-js';
I18n.locale = 'IT';

import { RootState } from '../../store';
import { enumRoutes } from '../../libs/enums';
import { Header, NavSlide, Search } from '../../components';
import { objFromUrlParams, filterList } from '../../libs/helpers/commons';

import { drugsAsyncGetListAction } from '../../store/features/drugsSlice';

let debounce: any;
const DrugsView: React.FC = () => {
  const dispatch = useDispatch();
  const urlParamsObj = objFromUrlParams();
  const drugs = useSelector((state: RootState) => state.drugs);

  const [search, searchSet] = useState<string>('')
  const [listFiltered, listFilteredSet] = useState<iNavItem[]>([])

  useEffect(() => {
    dispatch(drugsAsyncGetListAction(null));
  }, [dispatch]);

  // TODO riportare logica in hook comune
  useEffect(() => {
    listFilteredSet(drugs.list);
  }, [drugs.list]);

  useEffect(() => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      onFilterList()
    }, 300);
  }, [search]);

  function onFilterList() {
    if (drugs.list.length) {
      const arrayTreatments = filterList({search, list: drugs.list, linkType: 'drug'});
      listFilteredSet(arrayTreatments);
    }
  }

  function onSearch(text: string) {
    searchSet(text);
  }

  return (
    <>
      <Header
        title={I18n.t("frontend.drug", { count: 2 })}
        linkBack={enumRoutes.home}
      />
      <main>
        <Search value={search} onSearch={onSearch} placeholder={I18n.t("search")} />
        <NavSlide
          list={listFiltered}
          route={enumRoutes.drugsDetails}
          activeItem={urlParamsObj.active ? urlParamsObj.active : undefined}
        />
      </main>
    </>
  )
}

export default DrugsView;