/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { drugsGetListApi, drugsGetDetailsApi } from '../../api/drugsApi';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import { iDrugApi } from '../../libs/models';


interface iDrugsState {
  list: iDrugApi[];
  details: iDrugApi | null;
}



const initialState: iDrugsState = {
  list: [],
  details: null,
};

const slice = createSlice({
  name: 'drugs',
  initialState,
  reducers: {
    drugsSetListAction: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    drugsSetDetailsAction: (state, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
  },
});

export const { drugsSetListAction, drugsSetDetailsAction } = slice.actions;

export default slice.reducer;

export const drugsAsyncGetListAction = createAsyncThunk(
  'drugs/drugsAsyncGetListAction',
  async (payload: null, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: iDrugApi[] = await drugsGetListApi();
      dispatch(drugsSetListAction(response));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const drugsAsyncGetDetailsAction = createAsyncThunk(
  'drugs/drugsAsyncGetListAction',
  async (payload: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: iDrugApi = await drugsGetDetailsApi(payload);
      dispatch(drugsSetDetailsAction(response));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);