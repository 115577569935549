export interface ErrorMessageInterface {
  id: string | number;
  name: string;
  status: string | number;
  date: Date | string;
  message?: string;
  details?: string;
  code?: string | number;
}

export default class ErrorMessage extends Error {
  public id: string | number;
  public status: string | number;
  public date: Date | string;
  public details?: string;
  public code?: string | number;

  constructor(params: ErrorMessageInterface) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(params.message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorMessage);
    }

    this.id = params.id;
    this.name = params.name;
    this.status = params.status;
    this.date = params.date;
    this.details = params.details;
    this.code = params.code;
  }
}

// ErrorMessage.prototype = Object.create(Error.prototype);
// ErrorMessage.prototype.constructor = ErrorMessage;
