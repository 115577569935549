/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';

import drugsJson from '../../../../public/trees/drugs/tree.json';
import { findDetailsOffline } from '../libs/helpers/commons';

const api = new Api(Config.apis.baseUrl);

export const drugsGetListApi = async (): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.drugsTree,
    method: 'GET',
  });
  if (call.body.offline) {
    return drugsJson;
  }

  const result = call.payload.ok ? call.body : [];
  return result;
};

export const drugsGetDetailsApi = async (id: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.drugs}/${id}`,
    method: 'GET',
  });
  if (call.body.offline) {
    const list = drugsJson;
    const drug = findDetailsOffline({ list, id })
    return drug;
  }

  const result = call.payload.ok ? call.body : [];
  return result;
};