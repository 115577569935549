/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iNavItem } from '../libs/models';
import clinicalConditionJson from '../../../../public/trees/clinical_conditions/tree.json';
import { findDetailsOffline } from '../libs/helpers/commons';


const api = new Api(Config.apis.baseUrl);
export const clinicalConditionsGetListApi = async (): Promise<iNavItem[]> => {
  const call = await api.makeCall({
    path: Config.apis.clinicalConditionsMenu,
    method: 'GET',
  });

  if (call.body.offline) {
    return clinicalConditionJson as iNavItem[];
  }
  const result: iNavItem[] = call.payload.ok ? call.body : [];
  return result;
};

export const clinicalConditionsGetDetailsApi = async (id: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.clinicalConditionsDetails}/${id}`,
    method: 'GET',
  });
  const result = call.payload.ok ? call.body : [];
  return result;
};

export const clinicalConditionsGetTreatmentApi = async (id: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.treatments}/${id}`,
    method: 'GET',
  });

  if (call.body.offline) {
    const list = clinicalConditionJson as iNavItem[];
    const treatment = findDetailsOffline({ list, id })
    return treatment;
  }

  const result = call.payload.ok ? call.body : [];
  return result;
};