import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import I18n from 'i18n-js';

import { RootState } from '../../store';
import { enumRoutes } from '../../libs/enums';
import { Header, Details, ItemLink } from '../../components';
import { iDrugApi } from '../../libs/models';
import { parseString } from '../../libs/helpers/commons';

import { drugsAsyncGetDetailsAction } from '../../store/features/drugsSlice';
import { iClinicalConditionsDetails, iTreatment } from '../../libs/models';

import './styles.sass';

I18n.locale = window.LOCALE;
interface iProps {
  match: any;
}

const DrugsDetailsView: React.FC<iProps> = ({ match }: iProps) => {
  const { params } = match;
  const { id } = params;
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(drugsAsyncGetDetailsAction(id));
  }, [dispatch, id]);

  const details: iDrugApi = useSelector((state: RootState) => state.drugs.details);

  return (
    <>
      <Header
        title={I18n.t("frontend.drug", { count: 2 })}
        linkBack={enumRoutes.drugs}
      />
      {details && (
        <Details>
          <h1>{details.name}</h1>
          {details.notes && (
            <>
              <h2>{I18n.t("activerecord.attributes.drug.notes")}</h2>
              <div className="px-20 py-10">{parseString(details.notes)}</div>
            </>
          )}
          {details.normal_dose && (
            <>
              <h2>{I18n.t("activerecord.attributes.drug.normal_dose")}</h2>
              <div className="px-20 py-10">{parseString(details.normal_dose)}</div>
            </>
          )}
          {details.dosage_changes && (
            <>
              <h2>{I18n.t("activerecord.attributes.drug.dosage_changes")}</h2>
              <div className="px-20 py-10">{parseString(details.dosage_changes)}</div>
            </>
          )}
          {details.treatments.length > 0 && (
            <>
              <h2>{I18n.t("activerecord.models.treatment", { count: 2 })}</h2>
              <ul className="ul-reset">
              {details.treatments.map((t: iTreatment) => {
                const path = `${enumRoutes.treatment.replace(':id', '')}${t.slug}`;
                return (
                  <li key={t.slug}>
                    <ItemLink path={path} label={t.title} />
                  </li>
                )
              })}
              </ul>
            </>
          )}
          <>
            <h2>{I18n.t("info")}</h2>
            <div className="px-20 py-10">{I18n.t("activerecord.attributes.drug.updated_at")}: {I18n.l("date.formats.default", details.updated_at)}</div>
          </>
        </Details>
      )}
    </>
  )
}

export default DrugsDetailsView;