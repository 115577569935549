import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react'
import history from '../../store/history';

import Images from '../../assets/images';
import NavBurger from '../navBurger/NavBurger';

import './styles.sass';

interface Props {
  title: string;
  linkBack?: string;
}

const Header: React.FC<Props> = ({ title, linkBack }: Props) => {
  const [isNavburgerOpen, navBurgerSet] = useState(false);
  return (
    <header className="l-header">
      <div className="l-header__container">
        {linkBack ? (
          <Link to={linkBack} className="ant-btn--link">
            <img src={Images.backWhite.src} alt={Images.backWhite.src} />
          </Link>
        ) : (
          <button onClick={() => history.goBack()} className="ant-btn--link">
            <img src={Images.backWhite.src} alt={Images.backWhite.src} />
          </button>
        )}
        <div className="l-header__title">
          <img src={Images.icoPill.src} alt={Images.icoPill.alt} className="pill" />
          <h1>{title}</h1>
        </div>
        <div className="burger">
          <Hamburger toggled={isNavburgerOpen} toggle={navBurgerSet} size={20} color="#fff" />
        </div>
      </div>
      <NavBurger visible={isNavburgerOpen} />
    </header>
  );
};

export default Header;
