export interface ErrorAPIInterface {
  status: string;
  response?: unknown;
}

export default class ErrorAPI extends Error {
  public status: string;

  public response?: unknown;

  constructor(status: string, response?: unknown) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(status);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorAPI);
    }

    this.status = status;
    this.response = response;
  }
}
