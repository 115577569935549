import { configureStore, Action } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThunkAction } from 'redux-thunk';
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import history from "./history";
import rootReducer from './rootReducer';
import appMiddlewares from "../libs/middlewares/index";
import { register } from '../libs/helpers/ioc';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const middlewares = [routerMiddleware(history), ...appMiddlewares];
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

export type AppDispatch = typeof store.dispatch;

// After adding redux-persist to avoid type errors of RootState inside a component
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
// persistor.purge();
register('store', store);

export default store;