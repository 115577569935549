import React from "react";
import './styles.sass';



const NoServiceWorker: React.FC = () => {
  return (
    <div className="no-service-worker__alert">
      <div className="no-service-worker__alert-main">
        Ci sono problemi con la visualizzazione.
        <br/>
        Si consiglia di accedere con un telefono cellulare o un tablet.
      </div>
    </div>
  );
};

export default NoServiceWorker;
