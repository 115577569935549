import React, {useEffect, useState} from "react";
import { Link, withRouter } from 'react-router-dom';

import { enumRoutes } from '../../libs/enums';
import { iNavItem, iNavLink } from '../../libs/models';

import NavSlideItem from './navSlideItem';
import './styles.sass';

interface iProps {
  list: iNavItem[];
  route: enumRoutes;
  activeItem?: string | undefined;
}

const NavSlide: React.FC<iProps> = ({ activeItem, list, route }: iProps) => {
  const baseRoute = route.replace(':id', '');

  const [listParsed, listParsedSet] = useState<any[]>([])
  const [step, stepSet] = useState<number>(0);
  const [activeItems, activeItemsSet] = useState<any>({});
  const [activeItemFound, activeItemFoundSet] = useState<boolean>(false);

  useEffect(() => {
    const newList = typeof list !== 'string' ? list : []
    listParsedSet(newList)
  }, [list])


  function nextStepClicked() {
    stepSet(step + 1);
  }

  function backClicked(level: number) {
    stepSet(step - 1);
    const newActiveItems = {...activeItems};
    const nextKey = `level-${level}`;
    delete newActiveItems[nextKey];
    activeItemsSet(newActiveItems);
  }

  function onItemClicked(keyValue: string, keyLevel: number) {
    const nextKey = `level-${keyLevel}`;
    const newActiveItems = {
      ...activeItems,
      [nextKey]: keyValue
    }
    activeItemsSet(newActiveItems);
  }

  let activeFound = false;
  let level = 0
  let activeNameFound = '';
  const tempObj: any = {};

  function setItemFound(item: any) {
    activeNameFound = item.name;
    activeFound = true;
    activeItemFoundSet(true);
    return activeFound;
  }

  function findLevel(children: any, cycleLevel: number) {
    children.some((child: iNavItem) => {
      //TODO cycleLevel + 10 dovrebbe essere il numero di rami (livello massimo di children)
      for (let i = cycleLevel + 1; i < cycleLevel + 10; i++){
        delete tempObj[`level-${1}`];
      }
      // Subito DOPO aver eliminato da tempObj i livelli successivi a quello attuale, setto il livello attuale
      tempObj[`level-${cycleLevel}`] = child.name;

      if (!child.items && !child.children) {
        if (activeItem === child.id) {
          activeFound = true;
          return setItemFound(child);
        }
      }

      if (child.items && !activeFound) {
        child.items.forEach((item: iNavLink) => {
          if (activeItem === item.id) {
            activeFound = true;
            return setItemFound(item);
          }
        })
      }
      if (child.children?.length && !activeFound) {
        level = cycleLevel + 1
        findLevel(child.children, level)
      }
      return activeFound;
    });
  }

  if (!activeItemFound) {
    findLevel(listParsed, level)
    if (activeItem && Object.keys(tempObj).length) {
      activeItemsSet(tempObj);
    }
  }

  function buildMenu(menu: any, level: number, start = false) {
    return (
      <>
        {menu.map((item: iNavItem) => {
          return (
            <NavSlideItem
              key={item.name}
              activeItems={activeItems}
              backClicked={backClicked}
              baseRoute={baseRoute}
              buildMenu={buildMenu}
              item={item}
              nextStepClicked={nextStepClicked}
              onClicked={onItemClicked}
              level={level}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <div className={`nav nav__step-${step}`}>
        <ul className="nav__level-0 is-open">
          {buildMenu(listParsed, 0, true)}
        </ul>
      </div>
    </>
  );
};

export default NavSlide;
