import DrugsView from './drugs/drugs.view';
import DrugsDetailsView from "./drugsDetails/drugsDetails.view";
import ClinicalConditionsView from './clinicalConditions/clinicalConditions';
import ClinicalConditionsDetailsView from './clinicalConditionsDetails/clinicalConditionsDetails';
import HomeView from "./home/home.view";
import TreatmentView from './treatment/treatment';

export {
  DrugsView,
  DrugsDetailsView,
  ClinicalConditionsView,
  ClinicalConditionsDetailsView,
  HomeView,
  TreatmentView,
};
