import React, {useState} from "react";
import { Link, withRouter } from 'react-router-dom';

import { iNavItem, iNavLink } from '../../libs/models';
import NavItemLink from '../NavItemLink/NavItemLink';

import Images from '../../assets/images';

import './styles.sass';

type NodeClassType = "" | 'is-active' | 'is-open';

interface iProps {
  activeItems: any;
  backClicked: (level: number) => void;
  baseRoute: string;
  buildMenu: (item: any, level: number) => void;
  // item: iNavItem | iNavLink;
  item: any;
  onClicked: (itemName: string, level: number) => void;
  level: number;
  nextStepClicked: () => void;
}

const NavSlideItem: React.FC<iProps> = ({
  activeItems,
  backClicked,
  baseRoute,
  buildMenu,
  item,
  nextStepClicked,
  onClicked,
  level,
}: iProps) => {
  const [active, activeSet] = useState<boolean>(false);
  const hasChild = (item.children?.length > 0 || item.items?.length > 0);

  function onClick() {
    onClicked(item.name, level);
    nextStepClicked();
    activeSet(true);
  }

  function onBackClicked() {
    backClicked(level);
    activeSet(false);
  }

  const activeLevel = activeItems[`level-${level}`];
  const arrActiveItemsKeys = Object.keys(activeItems);
  const lastKey = arrActiveItemsKeys[arrActiveItemsKeys.length - 1];
  const lastKeyLevel = Number(lastKey?.split('-')[1]);

  let nodeClass:NodeClassType = '';
  if (activeLevel === item.name) {
    nodeClass = lastKeyLevel === level ? 'is-active' : 'is-open';
  }

  return (
    <li key={item.name} className={nodeClass}>
      <div>
        {hasChild ? (
          <button type="button" className="ant-btn--link" onClick={onClick}>
            <span>{item.name}</span>
            <img src={Images.arrow.src} alt="" />
          </button>
        ) : (
          <div>
            <NavItemLink path={`${baseRoute}${item.id}`} label={item.name} />
          </div>
        )}
      </div>
      {hasChild && (
        <ul className={`nav__level-${level + 1}`}>
          <li className="nav__back">
            <button type="button" onClick={onBackClicked} className="ant-btn--link">
              <img src={Images.arrow.src} alt="" />
              <span>{item.name}</span>
            </button>
          </li>
          {item.children?.length > 0 && (
            <>
              {buildMenu(item.children, level + 1)}
            </>
          )}
          {item.items?.length > 0 && (
            <>
              {item.items.map((c: any) => {
                return (
                  <li key={`${c.id}${c.name}`}>
                    <Link to={`${baseRoute}${c.id}`}>
                      <span>{c.name}</span>
                      <img src={Images.arrow.src} alt="" />
                    </Link>
                  </li>
                )
              })}
            </>
          )}
        </ul>
      )}
    </li>
  )
};

export default NavSlideItem;
