import Config from "../config/Config";
import { Api } from "../libs/callApi";

const api = new Api(Config.apis.baseUrl);

// eslint-disable-next-line import/prefer-default-export
export const getLoginApi = async (): Promise<string> => {
  const call = await api.makeCall({ path: Config.apis.user.login });
  const { title } = call.body;
  return title;
};
