import React from "react";
import './styles.sass';

interface iProps {
  children: any;
}

const AppLayout: React.FC<iProps> = ({ children }: iProps) => {
  return (
    <section className="app-layout">
      { children }
    </section>
  );
};

export default AppLayout;
